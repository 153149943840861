import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import type {
  BetlineLeagueTopPlayer,
  BetlineLeagueTopPlayers,
} from 'web/src/modules/sportline/types/rest';

export const enum TopPlayerListType {
  SCORERS = 'scorers',
  ASSISTS = 'assists',
}

interface TopPlayersList {
  type: TopPlayerListType;
  list: BetlineLeagueTopPlayer[];
}

interface UseLeagueTopPlayersProps {
  leagueTopPlayers: Ref<BetlineLeagueTopPlayers>;
}

interface UseLeagueTopPlayersComposable {
  canExpand: Ref<boolean>;
  isExpanded: Ref<boolean>;
  lists: Ref<TopPlayersList[]>;
}

export function useLeagueTopPlayers(
  props: UseLeagueTopPlayersProps,
): UseLeagueTopPlayersComposable {
  const { leagueTopPlayers } = props;

  const collapseAfterSize = 5;
  const isExpanded = ref(!!process.env.VUE_APP_PRERENDER);

  const scorersRaw = computed<BetlineLeagueTopPlayer[]>(
    () => (leagueTopPlayers.value.scorers || []).map(((player) => ({ ...player }))),
  );
  const assistsRaw = computed<BetlineLeagueTopPlayer[]>(
    () => (leagueTopPlayers.value.assists || []).map(((player) => ({ ...player }))),
  );
  const canExpand = computed(() => (scorersRaw.value.length > collapseAfterSize)
    || assistsRaw.value.length > collapseAfterSize);
  const scorers = computed(() => (isExpanded.value
    ? scorersRaw.value
    : scorersRaw.value.slice(0, collapseAfterSize)));
  const assists = computed(() => (isExpanded.value
    ? assistsRaw.value
    : assistsRaw.value.slice(0, collapseAfterSize)));

  const lists = computed<TopPlayersList[]>(() => [
    { type: TopPlayerListType.SCORERS, list: scorers.value },
    { type: TopPlayerListType.ASSISTS, list: assists.value },
  ].filter((list) => list.list.length > 0));

  return {
    canExpand,
    isExpanded,
    lists,
  };
}
