import type {
  RouteLocationNormalized,
} from 'vue-router';
import { toRef } from 'vue';

import { RouteName } from '@leon-hub/routing-config-names';

import type { AppVueRouter, AsyncAbstractPrefetchResult } from '@core/router';
import { useRootNavigationStore } from '@core/navigation';

import type { SportEventsLeaguePageNavigationService } from 'web/src/modules/sportline/submodules/league/types';
import useLeaguePageStore from 'web/src/modules/sportline/submodules/league/store/useLeaguePageStore';
import { isLeaguePageRouteName } from 'web/src/modules/sportline/submodules/navigation/guards';

import { useBaseLeaguePageNavigationGuard } from './useBaseLeaguePageNavigationGuard';

export function usePrerenderLeaguePageNavigationGuard(): SportEventsLeaguePageNavigationService {
  const leaguePageStore = useLeaguePageStore();
  const canDisplayAnything = toRef(leaguePageStore, 'canDisplayAnything');
  const {
    initialRequests,
    awaitStateIsLoaded,
    setConfigForLeagueLoading,
  } = leaguePageStore;

  const rootNavigationStore = useRootNavigationStore();
  const { setRouteContentLoaded } = rootNavigationStore;
  const baseNavigationGuard = useBaseLeaguePageNavigationGuard();
  const loadLeagueConfig = toRef(baseNavigationGuard, 'loadLeagueConfig');
  const {
    clearLoadLeagueConfig,
    prefetch: basePrefetch,
    getRedirectLocation,
  } = baseNavigationGuard;

  async function prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ): AsyncAbstractPrefetchResult {
    const superResult = basePrefetch(router, to, from);
    if (superResult !== undefined) {
      return superResult;
    }

    const loadPromise = awaitStateIsLoaded();

    setConfigForLeagueLoading({ ...loadLeagueConfig.value });
    clearLoadLeagueConfig();
    void initialRequests();

    return await loadPromise.then(() => {
      const loadingRouteName = isLeaguePageRouteName(to.name) ? to.name : RouteName.SPORT_LEAGUE;
      setRouteContentLoaded(loadingRouteName);

      if (!canDisplayAnything.value) {
        // @TODO split 301 and 302 redirects for just empty events and corrupted leagues
        return router.resolve302location({ name: RouteName.HOME });
      }

      const redirectTo = getRedirectLocation(router, to);

      if (redirectTo) {
        return redirectTo;
      }
    });
  }

  return { prefetch };
}
