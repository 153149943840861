<script lang="ts" setup>
import { computed, toRef } from 'vue';

import { IconName, IconSize } from '@leon-hub/icons';

import { ButtonKind, VButton } from '@components/buttons';

import VFavoriteToggle from 'web/src/components/FavoriteToggle/VFavoriteToggle/VFavoriteToggle.vue';
import {
  activeFavoriteIcon,
  inactiveFavoriteIcon,
} from 'web/src/modules/sportline/components/SportlineToolbar/constants';
import { useLeagueFavoriteIndicator } from 'web/src/modules/sportline/submodules/favorites/composables';

interface LeagueTitleBarProp {
  region?: string;
  name?: string;
  leagueId: string;
  sportId: string;
}

interface LeagueTitleBarEmits {
  (e: 'click-back'): void;
}

const props = withDefaults(defineProps<LeagueTitleBarProp>(), {
  region: '',
  name: '',
});

const emit = defineEmits<LeagueTitleBarEmits>();

const {
  isFavorite,
  isOnlyPending,
  hasFavoriteButton,
  toggleLeagueFavoriteState,
} = useLeagueFavoriteIndicator({
  sportId: toRef(props, 'sportId'),
  leagueId: toRef(props, 'leagueId'),
});

const leagueTitle = computed(() => [props.region, props.name].filter((part) => !!part).join(' | '));

function onClickBack(): void {
  emit('click-back');
}
</script>

<template>
  <div v-auto-id="'LargeLeagueToolbar'" :class="$style['sportline-toolbar']">
    <VButton
      :class="$style['sport-event-league-toolbar__back']"
      :icon-name="IconName.ARROW_LEFT"
      :icon-size="IconSize.SIZE_24"
      :kind="ButtonKind.TRANSPARENT"
      @click="onClickBack"
    />

    <div :class="$style['sportline-toolbar__title-block']">
      <span
        v-if="leagueTitle"
        :class="$style['sportline-toolbar__title']"
      >{{ leagueTitle }}</span>
    </div>

    <div :class="$style['sportline-toolbar__right-block']">
      <VFavoriteToggle
        v-if="hasFavoriteButton"
        :active-icon="activeFavoriteIcon"
        :inactive-icon="inactiveFavoriteIcon"
        :is-favorite="isFavorite"
        is-button
        :is-only-pending="isOnlyPending"
        @click="toggleLeagueFavoriteState"
      />
    </div>
  </div>
</template>

<style module lang="scss">
@import 'web/src/modules/sportline/components/SportlineToolbar/styles/sportline-toolbar';

.sport-event-league-toolbar {
  &__back {
    width: auto;
    min-width: 44px;
    margin: auto 0;
    color: var(--TextPrimary);
    background-color: inherit;
    border: 0;

    &:hover {
      color: var(--TextDefault);
    }
  }
}
</style>
