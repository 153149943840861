<script lang="ts" setup>
import { VLoaderMaskedDelayed } from 'web/src/components/Loader';
import StatisticDisclaimer from 'web/src/modules/sportline/submodules/core-statistic/components/StatisticDisclaimer.vue';
import StatisticLeagueStandingBlock
  from 'web/src/modules/sportline/submodules/core-statistic/components/StatisticLeagueStandingBlock.vue';
import LeagueMatchesResults from 'web/src/modules/sportline/submodules/league-statistic/components/LeagueMatchesResults.vue';
import LeagueMatchesStatistic
  from 'web/src/modules/sportline/submodules/league-statistic/components/LeagueMatchesStatistic.vue';
import LeaguePlayoff from 'web/src/modules/sportline/submodules/league-statistic/components/playoff/LeaguePlayoff.vue';
import LeagueTopPlayers
  from 'web/src/modules/sportline/submodules/league-statistic/components/topPlayers/LeagueTopPlayers.vue';
import EmptyLeaguePageContentInfo
  from 'web/src/modules/sportline/submodules/league/components/EmptyLeaguePageContentInfo.vue';
import { LeaguePageSectionId } from 'web/src/modules/sportline/submodules/league/enums';

import { useLeaguePageStatistic } from './useLeaguePageStatistic';

const {
  leagueStandings,
  leagueMatchesStatistic,
  leagueMatchesResults,
  leaguePlayoff,
  leagueTopPlayers,
  isReady,
  isLoading,
  isEmpty,
} = useLeaguePageStatistic();
</script>

<template>
  <div v-auto-id="'LeaguePageStatistic'" :class="$style['sport-event-league-statistic']">
    <VLoaderMaskedDelayed
      v-if="!isReady && isLoading"
    />

    <template v-if="isReady">
      <div :class="$style['sport-event-league-statistic__content-title']">
        {{ $t('WEB2_SPORTLINE_PAGE_SECTION_STATISTIC') }}
      </div>

      <div :class="$style['sport-event-league-statistic__blocks']">
        <LeagueMatchesStatistic
          v-if="leagueMatchesStatistic"
          :class="$style['sport-event-league-statistic__block']"
          :league-matches-statistic="leagueMatchesStatistic"
        />
        <LeagueMatchesResults
          v-if="leagueMatchesResults"
          :class="$style['sport-event-league-statistic__block']"
          :league-matches-results="leagueMatchesResults"
        />
        <LeagueTopPlayers
          v-if="leagueTopPlayers"
          :class="$style['sport-event-league-statistic__block']"
          :league-top-players="leagueTopPlayers"
        />
        <StatisticLeagueStandingBlock
          v-if="leagueStandings && leagueStandings.length > 0"
          :class="$style['sport-event-league-statistic__block']"
          :league-standings="leagueStandings"
        />
        <LeaguePlayoff
          v-if="leaguePlayoff"
          :class="$style['sport-event-league-statistic__block']"
          :league-playoff="leaguePlayoff"
        />
      </div>

      <EmptyLeaguePageContentInfo
        v-if="isEmpty && !isLoading"
        :section-id="LeaguePageSectionId.Statistic"
      />
      <StatisticDisclaimer
        v-else
      />
    </template>
  </div>
</template>

<style lang="scss" module>
.sport-event-league-statistic {
  position: relative;

  &__content-title {
    @include medium\18\20;

    padding: 18px 0 18px 16px;
    color: var(--TextDefault);
  }

  &__blocks {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__block {
    margin: 0;
  }
}
</style>
