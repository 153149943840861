import type {
  RouteLocationNormalized,
  RouteLocationRaw,
} from 'vue-router';
import { toRef } from 'vue';

import type { BaseRouteNameType } from '@leon-hub/routing-config-names';
import { RouteName } from '@leon-hub/routing-config-names';

import type { AbstractPrefetchResult, AppVueRouter, AsyncAbstractPrefetchResult } from '@core/router';
import { useRootNavigationStore } from '@core/navigation';

import type { ConfigForLeagueLoading } from 'web/src/modules/sportline/submodules/league/types';
import useLeaguePageStore from 'web/src/modules/sportline/submodules/league/store/useLeaguePageStore';
import { isLeaguePageRouteName } from 'web/src/modules/sportline/submodules/navigation/guards';
import { useSportlinePersistLocation } from 'web/src/modules/sportline/submodules/persist-filters';
import { useRedirectBySportsTree } from 'web/src/modules/sportline/submodules/redirects/composables';
import { getSportlinePageNavigationParameters } from 'web/src/modules/sportline/utils/navigation';

import { useBaseLeaguePageNavigationGuard } from './useBaseLeaguePageNavigationGuard';
import { useLeagueNavigationBackButton } from './useLeagueNavigationBackButton';

interface UseDefaultLeaguePageNavigationGuardComposable {
  prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ): AbstractPrefetchResult;
}

export function useDefaultLeaguePageNavigationGuard(): UseDefaultLeaguePageNavigationGuardComposable {
  const { activateBackButton } = useLeagueNavigationBackButton();
  const leaguePageStore = useLeaguePageStore();
  const isReady = toRef(leaguePageStore, 'isReady');
  const currentLeagueId = toRef(leaguePageStore, 'loadedDataLeagueId');
  const canDisplayAnything = toRef(leaguePageStore, 'canDisplayAnything');
  const {
    initialRequests,
    awaitStateIsLoaded,
    awaitLeavePage,
    syncBackgroundRequests,
    updateDateFilterForLeagueLoading,
    setConfigForLeagueLoading,
  } = leaguePageStore;

  const baseNavigationGuard = useBaseLeaguePageNavigationGuard();
  const loadLeagueConfig = toRef(baseNavigationGuard, 'loadLeagueConfig');
  const {
    getRedirectLocation,
    clearLoadLeagueConfig,
    prefetch: basePrefetch,
  } = baseNavigationGuard;

  const navigationStore = useRootNavigationStore();
  const {
    setRouteContentLoaded,
    setOnlyFirstRouteContentLoading,
  } = navigationStore;

  /**
   * Fully reload league (when have not data or navigate between leagues)
   */
  async function loadLeagueForConfig(config: ConfigForLeagueLoading, routeName: BaseRouteNameType): Promise<void> {
    setOnlyFirstRouteContentLoading(routeName);
    setConfigForLeagueLoading(config);
    await initialRequests();
    setRouteContentLoaded(routeName);
  }

  /**
   * Just sync data when navigate from some page (not league page) to already loaded league data
   */
  function syncLeagueForConfig(config: ConfigForLeagueLoading, routeName: BaseRouteNameType): void {
    if (config.filter) {
      updateDateFilterForLeagueLoading(config.filter);
    }

    syncBackgroundRequests({});
    setRouteContentLoaded(routeName);
  }

  function handleRedirectForWrongParameters(router: AppVueRouter, to: RouteLocationNormalized): void {
    const redirectTo = getRedirectLocation(router, to);
    if (redirectTo) {
      void router.pushRedirect(redirectTo, 301);
    }
  }

  /**
   * Subscribe to load state and handle redirects after page is ready
   */
  async function handleRedirectsForEmptyPage(router: AppVueRouter, to: RouteLocationNormalized): Promise<void> {
    await awaitStateIsLoaded();
    if (canDisplayAnything.value) {
      // it is ok, we have some events in this league
      handleRedirectForWrongParameters(router, to);
      return;
    }

    const redirect = useRedirectBySportsTree({
      router,
      code: 301,
      allowedTargets: {
        sport: true,
        region: true,
      },
    });

    void redirect.make();

    void awaitLeavePage().then(() => {
      // clear timeout after try back to MISSING page
      redirect.clearTimeout();
    });
  }

  async function prefetch(
    router: AppVueRouter,
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ): AsyncAbstractPrefetchResult {
    const superResult = await basePrefetch(router, to, from);

    if (superResult !== undefined) {
      return superResult as RouteLocationRaw;
    }

    const config = { ...loadLeagueConfig.value };
    clearLoadLeagueConfig();
    const loadingRouteName = isLeaguePageRouteName(to.name) ? to.name : RouteName.SPORT_LEAGUE;
    const navigationParameters = getSportlinePageNavigationParameters(to);
    const isLoadingNewLeague = currentLeagueId.value
      && navigationParameters.leagueId !== String(currentLeagueId.value);

    const { setPersistLocation } = useSportlinePersistLocation();
    setPersistLocation('sports', navigationParameters, 'league');

    activateBackButton(navigationParameters);
    if (!isReady.value || isLoadingNewLeague) {
      void loadLeagueForConfig(config, loadingRouteName);
      void handleRedirectsForEmptyPage(router, to);
    } else {
      syncLeagueForConfig(config, loadingRouteName);
      handleRedirectForWrongParameters(router, to);
    }
  }

  return { prefetch };
}
