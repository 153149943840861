import type { Ref } from 'vue';
import { computed, toRef } from 'vue';

import type {
  BetlineLeagueData,
  BetlineLeagueRepresentationData,
} from 'web/src/modules/sportline/submodules/core-statistic/types';
import type {
  League,
  Region,
  Sport,
} from 'web/src/modules/sportline/types';
import useLeaguePageStore from 'web/src/modules/sportline/submodules/league/store/useLeaguePageStore';

interface UseLeaguePageHeadlineDataComposable {
  sport: Ref<Maybe<Sport>>;
  region: Ref<Maybe<Region>>;
  league: Ref<Maybe<League>>;
  leagueData: Ref<Maybe<BetlineLeagueData>>;
  leagueRepresentationData: Ref<Maybe<BetlineLeagueRepresentationData>>;
}

export function useLeaguePageHeadlineData(): UseLeaguePageHeadlineDataComposable {
  const leaguePageStore = useLeaguePageStore();
  const sportElement = toRef(leaguePageStore, 'headerDataSportElement');
  const regionElement = toRef(leaguePageStore, 'headerDataRegionElement');
  const leagueElement = toRef(leaguePageStore, 'headerDataLeagueElement');
  const leagueData = toRef(leaguePageStore, 'leagueData');
  const leagueRepresentationData = toRef(leaguePageStore, 'leagueRepresentationData');

  const sport = computed<Maybe<Sport>>(() => sportElement.value?.sport ?? null);
  const region = computed<Maybe<Region>>(() => regionElement.value?.region ?? null);
  const league = computed<Maybe<League>>(() => leagueElement.value?.league ?? null);

  return {
    sport,
    region,
    league,
    leagueData,
    leagueRepresentationData,
  };
}
