<script lang="ts" setup>
import { computed, onBeforeMount, toRef } from 'vue';

import type { Web2Image } from '@leon-hub/api-sdk';

import { getImageSourceFromRawImageData, useRecalculateImageSource } from '@components/image';

interface PageContentHeadlineLayoutProps {
  backgroundColor?: Maybe<string>;
  backgroundImage?: Maybe<Web2Image>;
  doDisplayWidget?: boolean;
  isFullWidthContent?: boolean;
  isProportionalWidget?: boolean;
}

const props = defineProps<PageContentHeadlineLayoutProps>();
const backgroundColor = toRef(props, 'backgroundColor', null);
const backgroundImage = toRef(props, 'backgroundImage', null);
const imageSource = computed(() => getImageSourceFromRawImageData(backgroundImage.value));

const {
  normalizedSrc,
  normalizeSrc,
} = useRecalculateImageSource({ imageSource });
const layoutStyles = computed(() => ({
  backgroundColor: backgroundColor.value || undefined,
  backgroundImage: normalizedSrc.value ? `url('${normalizedSrc.value}')` : undefined,
}));

onBeforeMount(normalizeSrc);
</script>

<template>
  <div v-auto-id="'PageContentHeadlineLayout'"
    :class="{
      [$style['page-content-headline-layout']]: true,
      [$style['page-content-headline-layout--default']]: !backgroundColor,
      [$style['page-content-headline-layout--colored']]: !!backgroundColor,
      [$style['page-content-headline-layout--backgrounded']]: !!backgroundImage,
    }"
    :style="layoutStyles"
  >
    <div
      :class="{
        [$style['page-content-headline-layout__content']]: true,
        [$style['page-content-headline-layout__content--strict-width']]: !isFullWidthContent,
        [$style['page-content-headline-layout__content--proportional']]: isProportionalWidget
          && doDisplayWidget
          && !!$slots.widget,
      }"
    >
      <slot name="content" />
    </div>

    <div
      v-if="doDisplayWidget && !!$slots.widget"
      :class="{
        [$style['page-content-headline-layout__widget']]: true,
        [$style['page-content-headline-layout__widget--proportional']]: isProportionalWidget,
      }"
    >
      <slot name="widget" />
    </div>
  </div>
</template>

<style lang="scss" module>
.page-content-headline-layout {
  display: flex;
  overflow: hidden;
  border-radius: 5px;
  transition: height 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  &--default {
    color: var(--BasedOnSportTextDefault);
    background-color: var(--Layer1);
  }

  &--colored {
    color: var(--BasedOnSportTextColored);

    h1,
    h2,
    h3,
    h4 {
      color: var(--BasedOnSportTextColored);
    }
  }

  &--backgrounded {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  &__content {
    flex-basis: 100%;
    margin: 0 auto;

    &--strict-width {
      max-width: 480px;
    }

    &--proportional {
      flex-basis: 50%;
    }
  }

  &__widget {
    &--proportional {
      flex-basis: 50%;
    }
  }
}
</style>
