import type { Ref } from 'vue';

import { useI18nLocale } from '@core/i18n';

import type { PluralFormFactories } from 'web/src/modules/core/utils/language';
import { selectPluralForm } from 'web/src/modules/core/utils/language';

interface UsePluralFormLabelsCacheComposable {
  getLabelFor(count: number): Ref<string>;
}

export function usePluralFormLabelsCache(
  formFactories: PluralFormFactories,
): UsePluralFormLabelsCacheComposable {
  const { locale } = useI18nLocale();

  const cache: Dictionary<Ref<string>> = {};

  function getLabelFor(count: number): Ref<string> {
    if (!cache[count]) {
      cache[count] = selectPluralForm(formFactories, count, locale.value);
    }

    return cache[count];
  }

  return {
    getLabelFor,
  };
}
