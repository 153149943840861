import type { Ref } from 'vue';
import { computed, ref } from 'vue';

import { safeParseInt } from '@leon-hub/utils';

import { useI18n } from '@core/i18n';

import type { BetlineLeagueMatchesResults } from 'web/src/modules/sportline/types/rest';
import { usePluralFormLabelsCache } from 'web/src/modules/core/composables/language';
import { getSafePercentage } from 'web/src/modules/core/utils/math';
import { compareDesc } from 'web/src/utils/sort';

interface MatchResult {
  key: string;
  label: string;
  value: number;
  valueLabel: string;
  percentage: number;
  percentageFixed: string;
}

interface UseLeagueMatchesResultsProps {
  leagueMatchesResults: Ref<BetlineLeagueMatchesResults>;
}

interface UseLeagueMatchesResultsComposable {
  resultsList: Ref<MatchResult[]>;
  goalsList: Ref<MatchResult[]>;
  isExpanded: Ref<boolean>;
  canExpand: Ref<boolean>;
}

export function useLeagueMatchesResults(
  props: UseLeagueMatchesResultsProps,
): UseLeagueMatchesResultsComposable {
  const { leagueMatchesResults } = props;

  const { $translate } = useI18n();

  const { getLabelFor: getLabelForTimes } = usePluralFormLabelsCache([
    (count) => $translate('WEB2_MATCH_STATISTIC_HAPPENED_TIMES_ONE', ref({ count: String(count) })),
    (count) => $translate('WEB2_MATCH_STATISTIC_HAPPENED_TIMES_TWO', ref({ count: String(count) })),
    (count) => $translate('WEB2_MATCH_STATISTIC_HAPPENED_TIMES_MANY', ref({ count: String(count) })),
  ]);
  const { getLabelFor: getLabelForGoals } = usePluralFormLabelsCache([
    (count) => $translate('WEB2_MATCH_STATISTIC_GOAL_ONE', ref({ count: String(count) })),
    (count) => $translate('WEB2_MATCH_STATISTIC_GOAL_TWO', ref({ count: String(count) })),
    (count) => $translate('WEB2_MATCH_STATISTIC_GOAL_MANY', ref({ count: String(count) })),
  ]);

  const canExpandAfterSize = 6;
  const isExpanded = ref(!!process.env.VUE_APP_PRERENDER);

  const overallResults = computed(() => leagueMatchesResults.value.overallResults ?? {});
  const overallGoals = computed(() => leagueMatchesResults.value.overallGoals ?? {});

  const totalResults = computed(() => Object.values(overallResults.value).reduce((result, value) => result + value, 0));
  const totalGoals = computed(() => Object.values(overallGoals.value).reduce((result, value) => result + value, 0));

  const rawResultsList = computed<MatchResult[]>(() => Object.keys(overallResults.value)
    .map((key: keyof typeof overallResults['value']) => {
      const percentage = getSafePercentage(overallResults.value[key], totalResults.value, 0);
      return {
        key,
        label: key,
        value: overallResults.value[key],
        valueLabel: getLabelForTimes(overallResults.value[key] || 0).value,
        percentage,
        percentageFixed: percentage.toFixed(1),
      };
    })
    .sort((a, b) => compareDesc(a.value, b.value)));
  const rawGoalsList = computed<MatchResult[]>(() => Object.keys(overallGoals.value)
    .map((key: keyof typeof overallGoals['value']) => {
      const value = safeParseInt(key);
      const percentage = getSafePercentage(overallGoals.value[key], totalGoals.value, 0);

      return {
        key,
        label: value !== null ? getLabelForGoals(value).value : key,
        value: overallGoals.value[key],
        valueLabel: getLabelForTimes(overallGoals.value[key] || 0).value,
        percentage,
        percentageFixed: percentage.toFixed(1),
      };
    })
    .sort((a, b) => compareDesc(a.value, b.value)));
  const canExpand = computed(() => (
    rawResultsList.value.length > canExpandAfterSize
    || rawGoalsList.value.length > canExpandAfterSize
  ));
  const resultsList = computed(() => (isExpanded.value
    ? rawResultsList.value
    : rawResultsList.value.slice(0, canExpandAfterSize)));
  const goalsList = computed(() => (isExpanded.value
    ? rawGoalsList.value
    : rawGoalsList.value.slice(0, canExpandAfterSize)));

  return {
    resultsList,
    goalsList,
    isExpanded,
    canExpand,
  };
}
