import { useNavigationBackButtonStore } from '@core/navigation';

import type { SportlinePageIdentifier } from 'web/src/modules/sportline/types/navigation';
import { resolveSportsPageLink } from 'web/src/modules/sportline/submodules/navigation/store/utils';

interface UseLeagueNavigationBackButtonComposable {
  activateBackButton(to: SportlinePageIdentifier): void;
}

export function useLeagueNavigationBackButton(): UseLeagueNavigationBackButtonComposable {
  const { setNavigationBackLocationGetter } = useNavigationBackButtonStore();

  function activateBackButton(to: SportlinePageIdentifier): void {
    setNavigationBackLocationGetter(() => resolveSportsPageLink({ urlName: to.sportFamily }));
  }

  return { activateBackButton };
}
