import type { Ref } from 'vue';
import { computed } from 'vue';

import type { Web2Image } from '@leon-hub/api-sdk';
import { IconName, IconSize } from '@leon-hub/icons';

import { useI18n } from '@core/i18n';

import type { SwitcherOption, VSwitcherProps } from 'web/src/components/Switcher/VSwitcher/types';
import type {
  BetlineLeagueData,
  BetlineLeagueRepresentationData,
} from 'web/src/modules/sportline/submodules/core-statistic/types';
import type { League, Sport } from 'web/src/modules/sportline/types';
import { SwitcherKind } from 'web/src/components/Switcher/VSwitcher/enums';
import { useLeagueRepresentation } from 'web/src/modules/sportline/submodules/core-statistic/composables';
import { LeaguePageSectionId } from 'web/src/modules/sportline/submodules/league/enums';

interface UseLeaguePageHeadlineProps {
  sport: Ref<Maybe<Sport>>;
  league: Ref<Maybe<League>>;
  leagueData: Ref<Maybe<BetlineLeagueData>>;
  leagueRepresentationData: Ref<Maybe<BetlineLeagueRepresentationData>>;
  hasMatches: Ref<boolean>;
  hasPromotions: Ref<boolean>;
  hasStatistic: Ref<boolean>;
  activeSectionId: Ref<LeaguePageSectionId>;
}

interface UseLeaguePageHeadlineComposable {
  backgroundColor: Ref<Maybe<string>>;
  backgroundImage: Ref<Maybe<Web2Image>>;
  switcherOptions: Ref<SwitcherOption[]>;
  switcherProperties: Ref<VSwitcherProps>;
  title: Ref<string>;
  subtitle: Ref<string>;
  logoUrl: Ref<Maybe<string>>;
  sportIconName: Ref<Maybe<string>>;
  hasTimeline: Ref<boolean>;
}

export function useLeaguePageHeadline(
  props: UseLeaguePageHeadlineProps,
): UseLeaguePageHeadlineComposable {
  const {
    sport,
    league,
    leagueData,
    leagueRepresentationData,
    hasMatches,
    hasPromotions,
    hasStatistic,
    activeSectionId,
  } = props;

  const { $translate } = useI18n();

  const {
    backgroundImage,
    backgroundColor,
  } = useLeagueRepresentation({ sport, leagueRepresentationData });
  const switcherOptions = computed<SwitcherOption[]>(() => {
    const options: SwitcherOption[] = [];

    if (hasMatches.value) {
      options.push({
        id: LeaguePageSectionId.EventsList,
        label: $translate('WEB2_SPORTLINE_PAGE_SECTION_MATCHES').value,
        iconName: IconName.PLATE_SCORE,
        iconSize: IconSize.SIZE_16,
      });
    }

    if (hasPromotions.value) {
      options.push({
        id: LeaguePageSectionId.Promotions,
        label: $translate('WEB2_SPORTLINE_PAGE_SECTION_PROMOTIONS').value,
        iconName: IconName.PROMOS,
        iconSize: IconSize.SIZE_16,
      });
    }

    if (hasStatistic.value) {
      options.push({
        id: LeaguePageSectionId.Statistic,
        label: $translate('WEB2_SPORTLINE_PAGE_SECTION_STATISTIC').value,
        iconName: IconName.STATISTIC,
        iconSize: IconSize.SIZE_12,
      });
    }

    return options;
  });
  const switcherProperties = computed<VSwitcherProps>(() => ({
    kind: !backgroundColor.value ? SwitcherKind.TRANSPARENT : SwitcherKind.TRANSPARENT_WHITE,
    activeId: activeSectionId.value,
    isSmall: true,
    isWideClickZone: true,
  }));
  const title = computed<string>(() => league.value?.name || '');
  const subtitle = computed<string>(() => sport.value?.name || '');
  const logoUrl = computed<Maybe<string>>(() => leagueRepresentationData.value?.logoUrl || null);
  const sportIconName = computed<Maybe<string>>(() => leagueRepresentationData.value?.icon
    || sport.value?.representation?.icon
    || null);
  const hasTimeline = computed<boolean>(() => !!leagueData.value?.currentSeasonStart
    && !!leagueData.value?.currentSeasonEnd);

  return {
    backgroundColor,
    backgroundImage,
    switcherOptions,
    switcherProperties,
    title,
    subtitle,
    logoUrl,
    sportIconName,
    hasTimeline,
  };
}
